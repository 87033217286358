<template>
  <!--begin::Card-->
  <div>
    <form
      class="form"
      novalidate="novalidate"
      id="product_form"
    >
      <div class="form-group">
        <label>Product Image</label>
        <img :src="form.logo" class="img-responsive" height="70" width="90">
        <br>
        <input type="file" v-on:change="onImageChange" class="form-control">
      </div>
      <div class="form-group">
        <label>Code</label>
        <input
          type="text"
          class="form-control form-control-solid form-control-lg"
          v-model="form.code"
          name="code"
          ref="code"
          placeholder="Product Code"
        />
        <span class="form-text text-muted"
          >Enter a unique product code for this item below.</span
        >
      </div>
      <div class="form-group">
        <label>Name</label>
        <input
          type="text"
          class="form-control form-control-solid form-control-lg"
          v-model="form.name"
          name="name"
          ref="name"
          placeholder="Product Name"
        />
        <span class="form-text text-muted">
          Enter the name of this item.
        </span>
      </div>
      <div class="form-group">
        <label>Printing Name</label>
        <input
          type="text"
          class="form-control form-control-solid form-control-lg"
          v-model="form.printing_name"
          name="printing_name"
          ref="printing_name"
          placeholder="Printing Name"
        />
        <span class="form-text text-muted">
          Enter the dish name to be printed for this item.
        </span>
      </div>
      <div class="form-group">
        <label>Description</label>
        <b-form-textarea
          id="textarea-small"
          v-model="form.description"
          ref="description"
          name="description"
          class="form-control form-control-solid form-control-lg"
          size="lg"
          placeholder="Description"
        ></b-form-textarea>
        <span class="form-text text-muted">
          Enter a description of this item for the menu page.
        </span>
      </div>
      <div class="form-group">
        <label>Hide Item</label>
        <b-form-radio-group
          v-model="form.hide_item"
          :options="options"
          name="hide_item"
          ref="hide_item"
        ></b-form-radio-group>
        <span class="form-text text-muted">
          Do not display this item on the menu
        </span>
      </div>
      <div class="form-group">
        <label>Is deliverable</label>
        <b-form-radio-group
          v-model="form.is_deliverable"
          :options="options"
          name="is_deliverable"
          ref="is_deliverable"
        ></b-form-radio-group>
        <span class="form-text text-muted">
          Is available for delevery or not.
        </span>
      </div>
      <div class="form-group">
        <label>Vegetarian</label>
        <b-form-radio-group
          v-model="form.vegetarian"
          :options="options"
          name="vegetarian"
          ref="vegetarian"
        ></b-form-radio-group>
        <span class="form-text text-muted">
          Is this item suitable for vegetarians.
        </span>
      </div>
      <div class="form-group">
        <label>Vegan</label>
        <b-form-radio-group
          v-model="form.vegan"
          :options="options"
          name="vegan"
          ref="vegan"
        ></b-form-radio-group>
        <span class="form-text text-muted">
          Is this item suitable for vegans.
        </span>
      </div>
      <div class="form-group">
        <label>Gluten Free</label>
        <b-form-radio-group
          v-model="form.gluten_free"
          :options="options"
          name="gluten_free"
          ref="gluten_free"
        ></b-form-radio-group>
        <span class="form-text text-muted">
          Is this item gluten-free.
        </span>
      </div>
      <div class="form-group">
        <label>Plant-based</label>
        <b-form-radio-group
          v-model="form.plant_based"
          :options="options"
          name="plant_based"
          ref="plant_based"
        ></b-form-radio-group>
        <span class="form-text text-muted">
          Is this item plant-based.
        </span>
      </div>
      <div class="form-group">
        <label>Spiciness</label>
        <b-form-select
          v-model="form.spice"
          ref="spice"
          name="spice"
          :options="spicyness"
        ></b-form-select>
        <span class="form-text text-muted"
          >Please choose the level of spiciness below.</span
        >
      </div>
      <div class="form-group">
        <label>Category</label>
        <b-form-select
          v-model="form.category_id"
          ref="category_id"
          name="category_id"
          :options="categories"
        ></b-form-select>
        <span class="form-text text-muted">
         Please select product category.
        </span>
      </div>
      <!-- <div class="form-group">
        <label>Parent</label>
        <b-form-select
          v-model="form.parent"
          ref="parent"
          name="parent"
          :options="parents"
        ></b-form-select>
        <span class="form-text text-muted">
          Does this product is a sub product? Please select parent product.
        </span>
      </div> -->
      <div class="form-group">
        <label>Price</label>
        <input
          type="text"
          class="form-control form-control-solid form-control-lg"
          v-model="form.price"
          ref="price"
          name="price"
          placeholder="Price"
        />
        <span class="form-text text-muted">
          Enter the price for a single item.
        </span>
      </div>
      <div class="form-group">
        <label>Toppings</label>
        <b-form-select
          v-model="form.topping"
          ref="toppings"
          name="toppings"
          :options="toppings"
          multiple
        ></b-form-select>
        <span class="form-text text-muted">
          Does this product come with optional toppings, if so select the topping group appropriate to this item.
        </span>
      </div>
      <div class="form-group">
        <label>Dish Properties</label>
        <b-form-checkbox-group
          v-model="form.dish_property"
          ref="dish_property"
          :options="dish_properties"
          name="flavour-2a"
          stacked
        ></b-form-checkbox-group>
        <span class="form-text text-muted">
          Tick which optional properties this dish allows.
        </span>
      </div>
      <div class="row justify-content-end">
        <button
          ref="product_form_submit"
          class="btn btn-primary mt-3 mr-4"
        >
          Submit
        </button>
      </div>
    </form>
  </div>
  <!--end::Card-->
</template>

<script>
import ApiService from "@/core/services/api.service";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import KTUtil from "@/assets/js/components/util";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import Swal from "sweetalert2";

export default {
  name: "MenuProductForm",
  props: ['id'],
  data() {
    return {
      form: {
        is_deliverable: '1',
        logo: '',
        name: '',
        code: '',
        printing_name: '',
        description: '',
        hide_item: "0",
        vegetarian: "0",
        vegan: "0",
        gluten_free: "0",
        plant_based: "0",
        price: 0,
        spice: null,
        topping: [],
        parent: null,
        category_id: null,
        dish_property: [],
      },
      options: [
        { text: "Yes", value: "1" },
        { text: "No", value: "0" }
      ],
      spicyness: [
        { value: null, text: "Please Select Spiciness" },
        { value: 'Medium', text: "Medium" },
        { value: 'Hot', text: "Hot" },
        { value: 'Extra Hot', text: "Extra Hot" },
      ],
      toppings: [
        { value: null, text: "Please Select Toppings", disabled: true },
      ],
      parents: [
        { value: null, text: "Please Select Parent Product" },
      ],
      categories: [
        { value: null, text: "Please Select Product Category" },
      ],
      dish_properties: [],
    };
  },
  mounted() {
    const product_form = KTUtil.getById("product_form");
    this.fv = formValidation(product_form, {
      fields: {
        name: {
          validators: {
            notEmpty: {
              message: "Product name is required"
            }
          }
        },
        code: {
          validators: {
            notEmpty: {
              message: "Product code is required"
            }
          }
        },
        printing_name: {
          validators: {
            notEmpty: {
              message: "Printing name is required"
            }
          }
        },
        category_id: {
          validators: {
            notEmpty: {
              message: "Product category is required"
            }
          }
        },
        dish_property: {
          validators: {
            notEmpty: {
              message: "Product dish property is required"
            }
          }
        },
        price: {
          validators: {
            notEmpty: {
              message: "Product price is required"
            }
          }
        },
        topping: {
          validators: {
            notEmpty: {
              message: "At least one topping group is required"
            }
          }
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv.on("core.form.valid", () => {
      const form = this.form;
      const submitButton = this.$refs["product_form_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      var url = 'business/menu/products';
      let formData = new FormData();

      if (this.id) {
        url = 'business/menu/products/' + this.id;
        formData.append('_method', 'PUT');
        form._method = 'PUT';
      }

      formData.append('logo', this.form.logo);
      formData.append('name', this.form.name);
      formData.append('code', this.form.code);
      formData.append('printing_name', this.form.printing_name);
      formData.append('description', this.form.description);
      formData.append('hide_item', this.form.hide_item);
      formData.append('is_deliverable', this.form.is_deliverable);
      formData.append('vegetarian', this.form.vegetarian);
      formData.append('vegan', this.form.vegan);
      formData.append('gluten_free', this.form.gluten_free);
      formData.append('plant_based', this.form.plant_based);
      formData.append('spice', this.form.spice);
      formData.append('price', this.form.price);
      formData.append('topping', this.form.topping);
      formData.append('parent', this.form.parent);
      formData.append('category_id', this.form.category_id);
      formData.append('dish_property', this.form.dish_property);
      ApiService.post(url, formData)
        .then(({ data }) => {
          this.$emit('success');
        })
        .catch(({ response }) => {
          Swal.fire({
            title: "",
            text: "Form",
            icon: "error",
            confirmButtonClass: "btn btn-primary",
            heightAuto: false
          });
        });

      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    });

    this.getCategories();
    this.getToppings();
    this.getProperties();

    if (this.id)
      this.get(this.id);
  },
  methods: {
    onImageChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.createImage(files[0]);
    },
    createImage(file) {
      let reader = new FileReader();
      let vm = this;
      reader.onload = (e) => {
        vm.form.logo = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    get(id) {
      ApiService.get('business/menu/products', id)
        .then(({ data }) => {
          let product = data.data;
          this.form.logo = product.logo;
          this.form.name = product.name;
          this.form.printing_name = product.printing_name;
          this.form.code = product.code;
          this.form.description = product.description;
          this.form.price = product.price;
          this.form.hide_item = product.hide_item;
          this.form.is_deliverable = product.is_deliverable;
          this.form.vegetarian = product.vegetarian;
          this.form.vegan = product.vegan;
          this.form.gluten_free = product.gluten_free;
          this.form.plant_based = product.plant_based;
          this.form.spice = product.spice;
          this.form.parent = product.parent_id;
          this.form.category_id = product.category_id;

          product.topping_groups.forEach((topping) => {
            this.form.topping.push(topping.id);
          });

          product.property_groups.forEach((property) => {
            this.form.dish_property.push(property.id);
          });
        });
    },
    getCategories() {
      ApiService.get('business/menu/categories')
        .then(({ data }) => {
          let categories = data.data;
          categories.forEach((category) => {
            this.categories.push({
              value: category.id,
              text: category.name,
            });
          });
        });
    },
    getToppings() {
      ApiService.get('business/menu/topping-groups')
        .then(({ data }) => {
          let toppings = data.data;
          toppings.forEach((topping) => {
            this.toppings.push({
              value: topping.id,
              text: topping.name,
            });
          });
        });
    },
    getProperties() {
      ApiService.get('business/menu/property-groups')
        .then(({ data }) => {
          let properties = data.data;
          properties.forEach((property) => {
            this.dish_properties.push({
              value: property.id,
              text: property.name,
            });
          });
        });
    }
  }
};
</script>