<template>
  <div>
    <v-row class="mt-5 mx-1 ">
      <v-col cols="12" sm="6" md="8">
        <h1><b>Products</b></h1>

      </v-col>
      <v-col cols="12" sm="6" md="4" class="d-flex justify-end align-end">
        <div class="d-flex">
          <v-btn medium class="bg-primary" dark @click="addNew">
            <v-icon left>mdi-plus</v-icon>Add New
          </v-btn>
          <v-btn outlined medium class="mx-2 text-light" style="background-color: red;" @click="deleteAll">
            <v-icon left>mdi-delete</v-icon>Delete All Products
          </v-btn>
        </div>
      </v-col>

    </v-row>
    <v-row class="mt-5 mx-1">
      
      <v-col cols="12" sm="6" md="4">
        <div class="card-toolbar" style="display: grid;">
          <label for>
            <h1><b>Product Toppings</b></h1>
          </label>
          <b-form-file
            v-model="productToppingFile"
            placeholder="Choose a file to upload"
            drop-placeholder="Drop file here..."
            class="mb-1"
          ></b-form-file>
          <button
            v-on:click="saveToppings"
            v-if="productToppingFile != null"
            class="btn btn-success"
          >
            <i class="flaticon2-add-1"></i>Upload Product Toppings
          </button>
        </div>
      </v-col>

      <v-col cols="12" sm="6" md="4">
        <div class="card-toolbar" style="display: grid;">
          <label for>
            <h1><b>Product Properties</b></h1>

          </label>
          <b-form-file
            v-model="productPropertyFile"
            placeholder="Choose a file to upload"
            drop-placeholder="Drop file here..."
            class="mb-2"
          ></b-form-file>
          <button
            v-on:click="saveProperties"
            v-if="productPropertyFile != null"
            class="btn btn-success"
          >
            <i class="flaticon2-add-1"></i>Upload Product Properties
          </button>
        </div>
      </v-col>

      <v-col cols="12" sm="6" md="4">
        <div class="card-toolbar" style="display: grid;">
          <label for>
            <h1><b>Products</b></h1>

          </label>
          <b-form-file
            v-model="productFile"
            placeholder="Choose a file to upload"
            drop-placeholder="Drop file here..."
            class="mb-2"
          ></b-form-file>
          <button v-on:click="save" v-if="productFile != null" class="btn btn-success">
            <i class="flaticon2-add-1"></i>Upload Products
          </button>
        </div>
      </v-col>
      <v-col cols="12" sm="6" md="6">

      <div class="form-group ">
       <v-text-field
         type="text"
         v-model="search"
         v-on:keyup.enter="get('')"
         outlined
         placeholder="Search in Products"
       />
     </div>
     </v-col>
    </v-row>


      <!-- <v-col cols="12" sm="6" md="3" class="pt-14">
      
      </v-col> -->

    <div class="card card-custom mb-10">
 

 <div class="card-body">
  
   <!-- <div class="d-flex justify-content-between border-top pt-5">
     <div class="mr-2">
       <button
         class="btn btn-light-success font-weight-bold text-uppercase px-3 py-2"
         data-wizard-type="action-prev"
         :disabled="prev == null"
         v-on:click="get(prev)"
       >
         << Previous
       </button>
     </div>
     <div>
       <button
         class="btn btn-light-danger font-weight-bold text-uppercase px-3 py-2"
         data-wizard-type="action-next"
         :disabled="next == null"
         v-on:click="get(next)"
       >
         Next >>
       </button>
     </div>
   </div>-->
   <br />
   <div class="tab-content">
    <v-data-table
  :headers="headers"
  :items="products"
  :items-per-page="limit"
  :page.sync="currentPage"
  :server-items-length="totalItems"
  @update:page="get"
  @update:items-per-page="get"
  :search="search"
  item-key="id"
  class="data-table-container"
>
  <!-- Desktop View -->
  <template v-slot:body="{ items }" v-if="!isMobileView">
    <tbody>
      <tr v-for="item in items" :key="item.id">
        <td>{{ item.id }}</td>
        <td>
          <v-avatar size="40px" v-if="item.logo">
            <img :src="item.logo" alt="Product Image" />
          </v-avatar>
        </td>
        <td>{{ item.code }}</td>
        <td>{{ item.name }}</td>
        <td>{{ item.price }}</td>
        <td>{{ item.category_name }}</td>
        <td>{{ item.hide_item ? 'Yes' : 'No' }}</td>
        <td>{{ item.vegetarian ? 'Yes' : 'No' }}</td>
        <td>{{ item.vegan ? 'Yes' : 'No' }}</td>
        <td>{{ item.gluten_free ? 'Yes' : 'No' }}</td>
        <td>{{ item.plant_based ? 'Yes' : 'No' }}</td>
        <td>{{ item.spice }}</td>
        <td>
          <v-icon medium class="mr-2" @click="edit(item.id)">mdi-pencil</v-icon>
          <v-icon medium @click="deleteItem(item.id)">mdi-delete</v-icon>
        </td>
      </tr>
    </tbody>
  </template>

  <!-- Mobile View -->
  <template v-slot:item="{ item }" v-else>
    <v-row class="mb-2" no-gutters>
      <v-col cols="12" class="d-flex flex-column border-bottom py-2">
        <v-row>
          <v-col cols="6" class="font-weight-bold">{{ headers[0].text }}:</v-col>
          <v-col cols="6">{{ item.id }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="font-weight-bold">{{ headers[1].text }}:</v-col>
          <v-col cols="6">
            <v-avatar size="40px" v-if="item.logo">
              <img :src="item.logo" alt="Product Image" />
            </v-avatar>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="font-weight-bold">{{ headers[2].text }}:</v-col>
          <v-col cols="6">{{ item.code }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="font-weight-bold">{{ headers[3].text }}:</v-col>
          <v-col cols="6">{{ item.name }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="font-weight-bold">{{ headers[4].text }}:</v-col>
          <v-col cols="6">{{ item.price }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="font-weight-bold">{{ headers[5].text }}:</v-col>
          <v-col cols="6">{{ item.category_name }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="font-weight-bold">{{ headers[6].text }}:</v-col>
          <v-col cols="6">{{ item.hide_item ? 'Yes' : 'No' }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="font-weight-bold">{{ headers[7].text }}:</v-col>
          <v-col cols="6">{{ item.vegetarian ? 'Yes' : 'No' }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="font-weight-bold">{{ headers[8].text }}:</v-col>
          <v-col cols="6">{{ item.vegan ? 'Yes' : 'No' }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="font-weight-bold">{{ headers[9].text }}:</v-col>
          <v-col cols="6">{{ item.gluten_free ? 'Yes' : 'No' }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="font-weight-bold">{{ headers[10].text }}:</v-col>
          <v-col cols="6">{{ item.plant_based ? 'Yes' : 'No' }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="font-weight-bold">{{ headers[11].text }}:</v-col>
          <v-col cols="6">{{ item.spice }}</v-col>
        </v-row>
        <!-- Add action buttons -->
        <v-row>
          <v-col cols="12" class="d-flex">
            <v-icon medium class="mr-2" @click="edit(item.id)">mdi-pencil</v-icon>
            <v-icon medium @click="deleteItem(item.id)">mdi-delete</v-icon>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </template>
</v-data-table>

     <!--end::Table-->
     <!-- Pagination -->
     <v-pagination v-model="currentPage" :length="totalPages" :total-visible="7" @input="get"></v-pagination>
   </div>
 </div>
 <b-modal id="product-modal" ref="product-modal" size="lg" title="Add Product" hide-footer>
   <MenuProductForm :id="currentId" @onClickCancel="hideModal" @success="formSuccess"></MenuProductForm>
 </b-modal>
</div>
  </div>

</template>
<script>
import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";
import MenuProductForm from "@/view/pages/menu/form-components/MenuProductForm";

export default {
  name: "Products",
  components: {
    MenuProductForm
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Products" }]);
    this.get();
  },
  data() {
    return {
      isMobileView: window.innerWidth <= 600, // Initialize based on current screen width
      dialog: false,
      singleSelect: false,
      selected: [],
      products: [],
      currentId: "",
      next: null,
      prev: null,
      productFile: null,
      productPropertyFile: null,
      productToppingFile: null,

      headers: [
        { text: "ID", value: "id", width: "50px",sortable: false  },
        { text: "Image", value: "logo", width: "80px",sortable: false  },
        { text: "Product Code", value: "code", width: "120px",sortable: false  },
        { text: "Name", value: "name", width: "120px",sortable: false  },
        { text: "Price", value: "price", width: "80px" ,sortable: false },
        { text: "Category", value: "category_name", width: "120px",sortable: false  },
        { text: "Hide Item", value: "hide_item", width: "100px",sortable: false  },
        { text: "Vegetarian", value: "vegetarian", width: "100px" ,sortable: false },
        { text: "Vegan", value: "vegan", width: "100px" ,sortable: false },
        { text: "Gluten Free", value: "gluten_free", width: "100px" ,sortable: false },
        { text: "Plant Based", value: "plant_based", width: "100px",sortable: false  },
        { text: "Spicyness", value: "spice", width: "100px",sortable: false  },
        { text: "Actions", value: "actions", width: "100px",sortable: false  }
      ],
      currentPage: 1,
      limit: 10,
      totalItems: 0,
      search: "",
      totalPages: 0
    };
  },
  methods: {
    save() {
      let formData = new FormData();
      if (this.productFile != null) {
        formData.append("file", this.productFile);
        ApiService.fileUpload("business/import/products", formData)
          .then(({ data }) => {
            if (data == 1) {
              Swal.fire("Success", "File Uploaded Successfully", "success");
              this.get();
            } else {
              Swal.fire("Error", data, "error");
            }
          })
          .catch(({ response }) => {
            console.log(response);
          });
      }
    },
    saveToppings() {
      let formData = new FormData();
      if (this.productToppingFile != null) {
        formData.append("file", this.productToppingFile);
        ApiService.fileUpload("business/import/product-toppings", formData)
          .then(({ data }) => {
            if (data == 1) {
              Swal.fire("Success", "File Uploaded Successfully", "success");
              this.get();
            } else {
              Swal.fire("Error", data, "error");
            }
          })
          .catch(({ response }) => {
            console.log(response);
          });
      }
    },
    saveProperties() {
      let formData = new FormData();
      if (this.productPropertyFile != null) {
        formData.append("file", this.productPropertyFile);
        ApiService.fileUpload("business/import/product-properties", formData)
          .then(({ data }) => {
            if (data == 1) {
              Swal.fire("Success", "File Uploaded Successfully", "success");
              this.get();
            } else {
              Swal.fire("Error", data, "error");
            }
          })
          .catch(({ response }) => {
            console.log(response);
          });
      }
    },
    get() {
      ApiService.get(
        `business/menu/products?page=${this.currentPage}&limit=${this.limit}&search=${this.search}`
      )
        .then(({ data }) => {
          this.products = data.data;
          this.totalItems = data.meta.total;
          this.totalPages = data.meta.last_page;
        })
        .catch(error => {
          console.log(error);
        });
    },
    edit(id) {
      this.currentId = id;
      this.$refs["product-modal"].show();
    },
    hideModal() {
      this.$refs["product-modal"].hide();
    },
    addNew() {
      this.currentId = "";
      this.$refs["product-modal"].show();
    },
    deleteItem(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.isConfirmed) {
          ApiService.delete("business/menu/products/" + id).then(({ data }) => {
            this.get();
            Swal.fire("Deleted!", "Your product has been deleted.", "success");
          });
        }
      });
    },
    deleteAll() {
      Swal.fire({
        title: "This will delete all products",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.isConfirmed) {
          ApiService.post("business/menu/products/delete-all", []).then(
            ({ data }) => {
              this.get();
              Swal.fire(
                "Deleted!",
                "Your products has been deleted.",
                "success"
              );
            }
          );
        }
      });
    },
    formSuccess() {
      this.hideModal();
      this.get();
      Swal.fire({
        title: "",
        text: "The product has been successfully added!",
        icon: "success"
      });
    }
  }
};
</script>
<style>
.table-wrapper {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling for iOS */
}

.table-wrapper table {
  width: 100%; /* Ensure the table takes up the full width of the wrapper */
  min-width: 600px; /* Adjust this width based on your table's content */
}
</style>